import { signOut } from 'next-auth/client';
import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloLink } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import Sentry from '@/src/lib/initSentry';
import {
  CALLBACK_URL,
  HASURA_CLIENT_NAME,
} from '@/src/shared/config/constants';
import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();

// TODO: Evaluate if initialState and ctx should be typed
export default function createApolloClient({
  initialState,
  accessToken,
  ctx,
}: {
  initialState: any;
  accessToken: any;
  ctx: any;
}) {
  const wsLink = new WebSocketLink({
    uri: (process.env as any).GRAPHQL_HASURA_WS_API,
    options: {
      reconnect: true,
      connectionParams: () => {
        const Authorization = `Bearer ${accessToken}`;
        return {
          headers: {
            Authorization,
            'hasura-client-name': HASURA_CLIENT_NAME,
          },
        };
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const errorCode = extensions?.code;
        const errorMessage = `[GraphQL error]: Message: [${errorCode}] ${message}, Location: ${locations}, Path: ${path}`;

        toast({
          title: 'Oops, GraphQL error has occured!',
          description: errorMessage,
          status: 'error',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
        });

        return Sentry.captureException(errorMessage);
      });
    }

    if (networkError) {
      const networkErrorMessage = `[Network error]: ${networkError.message}`;
      toast({
        title: 'Oops, a network error has occurred!',
        description: networkErrorMessage,
        status: 'error',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });

      Sentry.captureException(networkErrorMessage);

      if (networkErrorMessage.includes('Could not verify JWT')) {
        signOut({
          callbackUrl: CALLBACK_URL,
        });
      }
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // @apollo/client/link/retry
    }
  });

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([errorLink, wsLink]),
    cache: new InMemoryCache().restore(initialState),
  });
}
